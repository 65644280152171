import { paramCase } from 'change-case';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// routes
import { useSnackbar } from 'notistack';
import { DateRangePicker } from '@mui/lab';
import axiosInstance from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { EventTableRow, EventTableToolbar } from '../../sections/@dashboard/event/list';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'featured'];


const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'start_date', label: 'Start Date', align: 'left' },
  { id: 'end_date', label: 'End Date', align: 'left' },
  { id: 'price', label: 'Price', align: 'left' },
  { id: 'creator', label: 'Creator', align: 'left' },
  { id: 'is_featured', label: 'Featured', align: 'left' },
  // { id: 'createdAt', label: 'Created On', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function EventList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');


  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({});

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const [value, setValue] = useState([new Date(), nextMonth]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (shouldUpdate) => {
    
    setOpen(false);
    if(shouldUpdate) {
      if(event.featured_from !== value[0] || event.featured_to !== value[1]) {
        axiosInstance.post(`/event/feature`, {
          event_id: event._id,
          featured_from: value[0].toISOString(),
          featured_to: value[1].toISOString(),
        })
        .then(res => {
          enqueueSnackbar('Event updated', { variant: 'success' });
          // update table data by _id
          const newTableData = tableData.map(item => {
            if(item._id === event._id) {
              return {
                ...item,
                featured_from: value[0].toISOString(),
                featured_to: value[1].toISOString(),
              };
            }
            return item;
          }
        );
          setTableData(newTableData);
        })
        .catch(err => {
          enqueueSnackbar('Error updating event', { variant: 'error' });
        });
      }
    }
    setEvent({});
  };



  const getEvents = useCallback(async () => {
    try {
      const response = await axiosInstance.post('/event/list?page=1&limit=100000');
  
      setTableData(response.data.docs);
     
  
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getEvents();
  },[getEvents]);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };



  const handleDeleteRow = async (id) => {
    // Unfeature event
    
      axiosInstance.post(`/event/unfeature`, {
        event_id: id,
      })
      .then(res => {
        enqueueSnackbar('Event updated', { variant: 'success' });
        // update table data by _id
        const newTableData = tableData.map(item => {
          if(item._id === id) {
            return {
              ...item,
              featured_from: null,
              featured_to: null,
            };
          }
          return item;
        }
      );
        setTableData(newTableData);
      })
      .catch(err => {
        enqueueSnackbar('Error updating event', { variant: 'error' });
      });
    
    
    
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleViewRow = (id) => {
    
  };

  const handleEditRow = (event) => {
    setEvent(event);
    if(event.featured_from !== null) {
      setValue([new Date(event.featured_from), new Date(event.featured_to)]);
    }else{
      setValue([new Date(), nextMonth]);
    }
    handleClickOpen();
  }



  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName)

  return (
    <Page title="Event: List">
      <Dialog open={open} onClose={()=>handleClose(false)}>
        <DialogTitle>Feature Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{my:2}} />
          <DateRangePicker
                startText="From"
                endText="To"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> </Box>
                    <TextField {...endProps} />
                    <Box sx={{ mx: 2 }}> </Box>
                    
                  </>
                )}
              />
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(false)}>Cancel</Button>
          <Button onClick={()=>handleClose(true)}>Update</Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Event List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Event', href: PATH_DASHBOARD.event.root },
            { name: 'List' },
          ]}
          
          
        />

        <Card>
        <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <EventTableToolbar
            filterName={filterName}
            
            onFilterName={handleFilterName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <EventTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row)}
                      
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.featured_from !== null);
  }


  return tableData;
}
