/* eslint-disable camelcase */
import { paramCase } from 'change-case';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { PATH_DASHBOARD } from '../../../../routes/paths';




// ----------------------------------------------------------------------

EventTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onStatusRow: PropTypes.func,
};

export default function EventTableRow({ row, selected, onViewRow, onEditRow, onSelectRow, onDeleteRow, onStatusRow }) {
  const theme = useTheme();

  const { id, name, start_date, end_date, price, user, featured_from, featured_to} = row;
  const navigate = useNavigate();

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      {/* <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell> */}

      <TableCell align="left">
        {name}
      </TableCell>

      <TableCell align="left">
        {format(new Date(start_date),'PPpp')}
      </TableCell>

      <TableCell align="left">
        {format(new Date(end_date),'PPpp')}
      </TableCell>

      <TableCell align="left">
        {price} Utoos
      </TableCell>
      
      <TableCell align="left">
        {user.username}
      </TableCell>

     

      

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(featured_from === null && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {featured_from === null ? 'No' : 'Yes'}
        </Label>
      </TableCell>
      

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
            <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Featured
              </MenuItem>
            
              
              {featured_from !== null && <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Un-Featured
              </MenuItem>}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
